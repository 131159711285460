import React from 'react'
import { Link } from 'gatsby'

const AboutUs7 = () => {
    return (
        <section id="about" className="about-ar section-padding" style={{ paddingTop: '5vw' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 valign" style={{ display: 'flex' }}>
                        <div className="content">
                            <h6 className="sub-title2 ls10 text-u">SAMPLE PROSPECT REPORT</h6>
                            <h6 style={{ textAlign: 'left', paddingBottom: '5vw' }}>
                                Experience the power of our KPI-focused
                                marketing campaigns, driving a steady
                                flow of insurance shoppers to your door step.
                                <br></br>
                                Check out our sample Auto quote request!
                            </h6>
                            {/* <Link to="/about/about-dark" className="butn bord curve mt-30" style={{ float: 'left' }}>
                                <span>Sign Up</span>
                            </Link> */}
                        </div>
                    </div>
                    <div className="col-lg-6 valign">
                        <div className="img">
                            <div class="col-lg-12 img md-mb50"><img src="/img/images-content/sample-prospect-report.svg" alt="Sample Prospect Report" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs7