import React from "react";
import { Formik, Form, Field } from "formik";
import ContactFromDate from "data/sections/form-info.json";

const ContactForm = () => {
  const messageRef = React.useRef(null);
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    //formData.append("state", values.state);



    fetch("http://www.pt.odyssey-agency.com/sendEmail.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        messageRef.current.innerText = data;
        values.name = "";
        values.lastName = "";
        values.email = "";
        values.phoneNumber = "";
        values.state = "";
        setTimeout(() => {
          messageRef.current.innerText = "";
        }, 2000);
      });
  };


  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-10">
            <div className="sec-head2">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                <span className="light-text">REACH OUT TO UNLOCK NEW POSSIBILITIES</span>
              </h6>
              <h3 className="wow color-font">
                Fill out the form bellow to get in touch with our sales team to start the conversation and learn about our product, pricing, and onboarding.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form md-mb50">
              <h4 className="fw-700 mb-50">Get Started in minutes.</h4>
              <Formik
                initialValues={{
                  name: "",
                  lastName: "",
                  email: "",
                }}
                onSubmit={handleSubmit}

              >
                <Form id="contact-form">
                  <div className="messages" ref={messageRef}></div>
                  <div className="controls">
                    <div className="form-group">
                      <Field
                        id="form_name"
                        type="text"
                        name="name"
                        placeholder="Your name"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="form_lastname"
                        type="text"
                        name="lastName"
                        placeholder="Your last name"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="form_email"
                        type="email"
                        name="email"
                        placeholder="Your email"
                        required="required"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="form_email"
                        type="text"
                        name="phoneNumber"
                        placeholder="Your phone number"
                        required="required"
                      />
                    </div>



                  </div>

                  <div className="submitForm" style={{ padding: '3vw' }}>
                    <button type="submit" className="butn bord">
                      <span>Submit</span>
                    </button>
                  </div>
                </Form>

              </Formik>

            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="cont-info">
              <h4 className="fw-700 mb-50">Contact Info.</h4>
              <h3 className="wow" data-splitting style={{ fontFamily: 'extralight' }}>
                {ContactFromDate.title}
              </h3>
              <div className="item mb-40">
                <h5>
                  <a href="#0">{ContactFromDate.email}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
