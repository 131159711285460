import React from 'react'
import { Link } from 'gatsby'

const AboutUs6 = () => {
  return (
    <section id="about" className="about-ar section-padding" style={{ paddingTop: '5vw' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="img">
              <div class="col-lg-12 img md-mb50"><img src="/img/images-content/marketing.svg" alt="Marketing Strategy" /></div>
            </div>
          </div>
          <div className="col-lg-6 valign">
            <div className="content">
              <h6 className="sub-title ls10 text-u">A WINNING<br></br>MARKETING STRATEGY</h6>
              <h6 style={{ textAlign: 'right' }}>
                Unleash your growth potential by acquiring your ideal customers at scale. Optimize your Cost Per Bind and maximize your ROI.
              </h6>
              {/* <Link to="/about/about-dark" className="butn bord curve mt-30" style={{ float: 'right' }}>
                <span>Sign Up</span>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs6